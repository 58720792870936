import { useNavigate } from '@remix-run/react';

import { isGamePackLaunched } from '../../../../app/components/GamePack/utils';
import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useGamePackContext } from '../../../pages/GamePack/Context';
import { type GamePack } from '../../../types/game';
import { assertExhaustive } from '../../../utils/common';
import { EditIcon } from '../../icons/EditIcon';
import {
  OrgSubscriptionUpgradeButton,
  useMyOrganizationFeatureChecker,
} from '../../Organization';
import { useUGCAnalytics } from '../UGC/utils';
import {
  GamePackButtonActions,
  GamePackButtonEdit,
  GamePackButtonPlay,
  GamePackButtonSchedule,
  GamePackOneTimePurchaseButton,
} from './GamePackButtons';

const btnClassName =
  'rounded h-10 flex items-center justify-center gap-2 px-4 text-sms';
const iconClassName = 'w-5 h-5 fill-current';

function GamePackFeaturedActionsNoPermission(props: { pack: GamePack }) {
  const featureChecker = useMyOrganizationFeatureChecker();
  const canPurchase = featureChecker.canPurchaseGamePackForOnd(props.pack);

  return (
    <div
      className='flex gap-4 items-start'
      onClick={(e) => e.stopPropagation()}
    >
      {canPurchase && <GamePackOneTimePurchaseButton pack={props.pack} />}
      <OrgSubscriptionUpgradeButton />
    </div>
  );
}

function GamePackFeaturedActionsTemplate(props: { pack: GamePack }) {
  const { pack } = props;
  const navigate = useNavigate();
  const analytics = useUGCAnalytics();
  const featureChecker = useMyOrganizationFeatureChecker();
  const ugcEnabled = useFeatureQueryParam('game-pack-ugc');
  const hasPermission =
    featureChecker.hasUnlimitedOndGameUGCAccess() || ugcEnabled;
  const ugcAIEnabled = useFeatureQueryParam('game-pack-ugc-ai');

  const handleClick = () => {
    analytics.trackTemplateCustomizeClicked({
      packId: pack.id,
      packName: pack.name,
    });
    const params = new URLSearchParams();
    params.set('template-id', pack.id);
    if (ugcAIEnabled) {
      params.set('game-pack-ugc-ai', 'enabled');
    }
    navigate(`/custom-games/create?${params.toString()}`);
  };

  if (hasPermission) {
    return (
      <button
        type='button'
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
        className={
          'btn-delete rounded h-10 flex items-center justify-center px-4 text-sms'
        }
      >
        <EditIcon className={'w-5 h-5 fill-current mr-2.5'} />
        <p>Customize</p>
      </button>
    );
  }
  return (
    <OrgSubscriptionUpgradeButton
      className={`w-full h-15 rounded p-0.5`}
      innerClassName='rounded'
    />
  );
}

export function GamePackFeaturedActionsPublicHome(props: { pack: GamePack }) {
  const { pack } = props;

  const featureChecker = useMyOrganizationFeatureChecker();
  const canPurchase = featureChecker.canPurchaseGamePackForOnd(props.pack);
  const canAccess = featureChecker.canAccessGamePackForOnd(props.pack);

  if (!!pack.isTemplate) {
    return <GamePackFeaturedActionsTemplate {...props} />;
  }

  if (canAccess) {
    return (
      <div
        className='flex gap-4 items-start'
        onClick={(e) => e.stopPropagation()}
      >
        {canPurchase ? (
          <GamePackOneTimePurchaseButton pack={props.pack} />
        ) : (
          <GamePackButtonSchedule
            pack={pack}
            context='featuredGamePack'
            className={`btn-delete ${btnClassName}`}
            iconClassName={iconClassName}
          />
        )}
        <GamePackButtonPlay
          pack={pack}
          context='featuredGamePack'
          className={`btn-secondary ${btnClassName}`}
          disabled={!isGamePackLaunched(pack)}
          iconClassName={iconClassName}
        />
      </div>
    );
  }
  return <GamePackFeaturedActionsNoPermission pack={pack} />;
}

export function GamePackFeaturedActionsAdmin(props: { pack: GamePack }) {
  const { pack } = props;

  return (
    <div
      className='flex items-center gap-4'
      onClick={(e) => e.stopPropagation()}
    >
      <GamePackButtonActions pack={pack} context='featuredGamePack' />
      <GamePackButtonEdit
        pack={pack}
        context='featuredGamePack'
        className={`btn-secondary ${btnClassName}`}
        iconClassName={iconClassName}
      />
    </div>
  );
}

export function GamePackFeaturedActionsEmbedded(props: { pack: GamePack }) {
  const { pack } = props;

  const featureChecker = useMyOrganizationFeatureChecker();
  const canAccess = featureChecker.canAccessGamePackForOnd(props.pack);

  if (canAccess) return null;
  return <GamePackFeaturedActionsNoPermission pack={pack} />;
}

export function GamePackFeaturedActions(props: { pack: GamePack }) {
  const { pack } = props;
  const ctx = useGamePackContext();

  if (ctx.embed) {
    if (ctx.embedCtx.isLegacyPublicHome)
      return <GamePackFeaturedActionsPublicHome pack={pack} />;
    return <GamePackFeaturedActionsEmbedded pack={pack} />;
  }

  switch (ctx.mode) {
    case 'admin':
      return <GamePackFeaturedActionsAdmin pack={pack} />;
    case 'public-home':
      return <GamePackFeaturedActionsPublicHome pack={pack} />;
    case 'host':
      return null;
    default:
      assertExhaustive(ctx.mode);
      return null;
  }
}
